import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import softwaredevelop from "../../../assets/images/resource/services_1.jpg";
import softwaretesting from "../../../assets/images/resource/services_2.jpg";
import cargoservice from "../../../assets/images/resource/services_3.jpg";
import uiux from "../../../assets/images/resource/services4.jpg";


const Service = () => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const servicesData = [
        {
            id: 1,
            title: 'Software Development',
            description: "Empowering Tomorrow with Comprehensive Full Stack Solutions.",
            icon: 'icon-04',
            href: '/services',
            data_aos_delay: '00ms',
        },
        {
            id: 2,
            title: 'Software Testing',
            description: "Precision in Code, Assurance in Quality - Elevating Software Excellence.",
            icon: 'icon-08',
            href: '/services-details',
            data_aos_delay: '200ms',
        },
        {
            id: 3,
            title: 'UI/UX Design',
            description: "Designing Intuitive Journeys, Inspiring Digital Connections.",
            icon: 'icon-08',
            href: '/services-details',
            data_aos_delay: '200ms',
        },
        {
            id: 4,
            title: 'Cargo Services',
            description: 'Efficiency in Motion, Reliability in Delivery - Your Cargo, Our Commitment.',
            icon: 'icon-12',
            href: '/services-details',
            data_aos_delay: '400ms',
        },

    ];
    return (
        <section className="service__section p_relative">
            <div className="auto-container">
                <div className="service__data">
                    <div className="title__data">
                        <div className="sub__title">
                            <h4>Better Future</h4>
                        </div>
                        <div className="title">
                            <h2>Explore Our Services</h2>
                        </div>
                    </div>
             
                    <ul class="accordion_1 web-service">
                        <li class="accordion_1__item">
                            <Link to="/services">
                                <img className="accordion_1__image" src={softwaredevelop} alt="" />
                                <div class="accordion_1__overlay"></div>
                                <h2 class="accordion_1__title">Software Development</h2>
                                <h5 class="accordion_1__description">"Empowering Tomorrow with Comprehensive Full Stack Solutions."</h5>
                            </Link>
                        </li>
                        <li class="accordion_1__item">
                        <Link to="/software-testing">
                            <img className="accordion_1__image" src={softwaretesting} alt="" />
                            <div class="accordion_1__overlay"></div>
                            <h2 class="accordion_1__title">Software Testing</h2>
                            <h5 class="accordion_1__description">"Precision in Code, Assurance in Quality - Elevating Software Excellence."</h5>
                      </Link>
                        </li>
                        <li class="accordion_1__item">
                            <img className="accordion_1__image" src={uiux} alt="" />
                            <div class="accordion_1__overlay"></div>
                            <h2 class="accordion_1__title">UI/UX Design</h2>
                            <h5 class="accordion_1__description">"Designing Intuitive Journeys, Inspiring Digital Connections."</h5>
                        </li>
                        <li class="accordion_1__item">
                        <Link to="/cargo-services">
                            <img className="accordion_1__image" src={cargoservice} alt="" />
                            <div class="accordion_1__overlay"></div>
                            <h2 class="accordion_1__title">Cargo Services</h2>
                            <h5 class="accordion_1__description"> "Efficiency in Motion, Reliability in Delivery - Your Cargo, Our Commitment."</h5>
                      </Link>
                        </li>

                    </ul>
                    <div className="row mob-service">
                        {servicesData.map((item, id)=>(
                            <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={id}>
                            <div className="service__block">
                                <div className="service__icon">
                                    <i className={item.icon}></i>
                                    <div className="service__icon__two"></div>
                                </div>
                                <div className="service__text">
                                    <h4><Link to={item.href}>{item.title}</Link></h4>
                                    <p>{item.description}</p>
                                </div>
                                <div className="service__button">
                                    <Link to={item.href}><i className="icon-05"></i></Link>
                                </div>
                            </div>
                       
                        </div>
                        
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Service;