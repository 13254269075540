import React, { useEffect, useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import basebg from "../../assets/images/resource/base.png";
import JsonData from '../../apisJson/serviceDetail.json';


const Services = () => {
    pageTitle('Services Details');

    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("id")

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [urlId, setUrlId] = useState(id)
    const [active, setActive] = useState(null)
    
    useEffect(() => {
        setUrlId(id)
    }, [id, active])
    
    
    const dynmicData = JsonData[urlId]


    const handleSubmit = (e) => {
        e.preventDefault();

        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);

        // Clear the form fields after submission if needed
        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    const servicesData = [
        { id: 1, title: 'Custom Software Development ', slug: '/services-details?id=0' },
        { id: 2, title: 'Web Application Development', slug: '/services-details?id=1' },
        { id: 3, title: 'Mobile App Development', slug: '/services-details?id=2' },
        { id: 4, title: 'E-commerce Solutions', slug: '/services-details?id=3' },
        { id: 5, title: 'Cloud Computing Services', slug: '/services-details?id=4' },
        { id: 6, title: 'Enterprise Software Solutions', slug: '/services-details?id=5' },
        { id: 7, title: 'API Development and Integration', slug: '/services-details?id=6' },
        { id: 8, title: 'Blockchain Development', slug: '/services-details?id=7' },
        { id: 9, title: 'Data Analytics and Business Intelligence', slug: '/services-details?id=8' },
        { id: 9, title: 'AI/ML Integration Service', slug: '' },

    ];

    const renderServices = () => {
        return servicesData.map((service) => (
            <li key={service.id} className={service.slug === '/custom-software-development' ? 'active' : ''}>
                <Link onClick={() => setActive(service?.id)} to={service.slug}>{service.title}</Link>
            </li>
        ));
    };

    return (

        <>
            <HeaderOne></HeaderOne>
            <BreadCrumb title={dynmicData.category}></BreadCrumb>
            <div className="service__details p_relative ">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="service__details__left">
                                <div className="sidebar__widget">
                                    <div className="all__service">
                                        <h4 className="">All Services</h4>
                                    </div>
                                    <ul className="service__details__1__cat">
                                        {renderServices()}
                                    </ul>
                                </div>
                                <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>
                                    <h3>Contact Us For Any Require</h3>
                                    <div className="services__icon">
                                        <i className="icon-19"></i>
                                    </div>
                                    <p>Need help?  Call us:</p>
                                    <h4>(+91) 782 856 7519</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="service__right">
                                <figure>
                                    <img src={require("../../../public/images/resource/" + dynmicData?.image)} alt="" />
                                </figure>
                                {/* <h2>Enhancing Real-world Experiences with AR <span>Technology.</span> </h2> */}
                                <h5 className="mt-4">{dynmicData.description}</h5>
                                <div className="row">
                                    <h3 className="mb-3">Key Features :</h3>
                                    {dynmicData.keyFeatures.map((featurename) => (
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="main_test">
                                                <div className="testing_content">
                                                    <h4>{featurename?.name}</h4>
                                                </div>
                                                <div className="testing_desc">
                                                    <p>{featurename?.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    <h3 className="mb-3">Technology Stack:</h3>
                                    {dynmicData.technologyStack.map((tech) => (
                                        <div className="col-lg-6 col-md-12 mb-3">
                                            <div className="main_technology">
                                                <div className="tech_content">
                                                    <h4>{tech?.name}</h4>
                                                </div>
                                                <div className="tech_desc">
                                                    <p>{tech?.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne></FooterOne>
        </>
    );
};

export default Services;
