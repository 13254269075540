import React, { useEffect, useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import basebg from "../../assets/images/resource/base.png";
import JsonData from '../../apisJson/softwareTesting.json';


const Services = () => {
    pageTitle('Services Details');

    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("id")

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [urlId, setUrlId] = useState(id)
    const [active, setActive] = useState(null)
    
    useEffect(() => {
        setUrlId(id)
    }, [id, active])
    
    
    const dynmicData = JsonData[urlId]



    const handleSubmit = (e) => {
        e.preventDefault();

        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);

        // Clear the form fields after submission if needed
        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    const servicesData = [
        { id: 1, title: 'Manual Testing', slug: '/software-testing-details?id=0' },
        { id: 2, title: 'Automated Testing', slug: '/software-testing-details?id=1' },
        { id: 3, title: 'Functional Testing', slug: '/software-testing-details?id=2' },
        { id: 4, title: 'Non-functional Testing', slug: '/software-testing-details?id=3' },
        { id: 5, title: 'Regression Testing', slug: '/software-testing-details?id=4' },
        { id: 6, title: 'Integration Testing', slug: '/software-testing-details?id=5' },
        { id: 7, title: 'User Acceptance Testing (UAT)', slug: '/software-testing-details?id=6' },
        { id: 8, title: 'Compatibility Testing', slug: '/software-testing-details?id=7' },
        { id: 9, title: 'Load Testing', slug: '/software-testing-details?id=8' },
        { id: 10, title: 'Security Testing', slug: '/software-testing-details?id=9' },
        { id: 11, title: 'Performance Testing', slug: '/software-testing-details?id=10' },
        { id: 12, title: 'Mobile App Testing', slug: '/software-testing-details?id=11' },
        { id: 13, title: 'API Testing', slug: '/software-testing-details?id=12' },


    ];

    const renderServices = () => {
        return servicesData.map((service) => (
            <li key={service.id} className={service.slug === '/software-testing-details?id=0' ? 'active' : ''}>
                <Link onClick={() => setActive(service?.id)} to={service.slug}>{service.title}</Link>
            </li>
        ));
    };

    return (

        <>
            <HeaderOne></HeaderOne>
            <BreadCrumb title={dynmicData.type}></BreadCrumb>
            <div className="service__details p_relative ">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="service__details__left">
                                <div className="sidebar__widget">
                                    <div className="all__service">
                                        <h4 className="">All Services</h4>
                                    </div>
                                    <ul className="service__details__1__cat">
                                        {renderServices()}
                                    </ul>
                                </div>
                                <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>
                                    <h3>Contact Us For Any Require</h3>
                                    <div className="services__icon">
                                        <i className="icon-19"></i>
                                    </div>
                                    <p>Need help?  Call us:</p>
                                    <h4>(+91) 782 856 7519</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="service__right">
                                <figure>
                                    <img src={require("../../../public/images/resource/" + dynmicData?.image)} alt="" />
                                </figure>
                                {/* <h2>Enhancing Real-world Experiences with AR <span>Technology.</span> </h2> */}
                                <h5 className="mt-4">{dynmicData.description}</h5>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="our_apporch m-1">
                                            <h3>Approach</h3>
                                            <p>{dynmicData.approach}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="our_Commitment m-1">
                                            <h3>Commitment</h3>
                                            <p>{dynmicData.commitment}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>Technology Involved:</h3>
                                    <h5>{dynmicData?.tech_description}</h5>
                                    {dynmicData?.tech_details?.map((tech) => (
                                        <div className="col-lg-12 col-md-12 mt-3 mb-3">
                                            <div className="main_test">
                                            <div className="testing_content">
                                                <h4>{tech?.name}</h4>
                                            </div>
                                            <div className="testing_desc">
                                                <p>{tech?.desc}</p>
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FooterOne></FooterOne>
        </>
    );
};

export default Services;
