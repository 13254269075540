import React, { useEffect, useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import basebg from "../../assets/images/resource/base.png";
import JsonData from '../../apisJson/cargoServices.json';


const Services = () => {
    pageTitle('Services Details');

    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("id")

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [urlId, setUrlId] = useState(id)
    const [active, setActive] = useState(null)
    
    useEffect(() => {
        setUrlId(id)
    }, [id, active])
    
    
    const dynmicData = JsonData[urlId]



    const handleSubmit = (e) => {
        e.preventDefault();

        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);

        // Clear the form fields after submission if needed
        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    const servicesData = [
        { id: 1, title: 'Booking Entry', slug: '/cargo-services-details?id=0' },
        { id: 2, title: 'Vessel Entry', slug: '/cargo-services-details?id=1' },
        { id: 3, title: 'Cargo Receipt Entry (CFS only)', slug: '/cargo-services-details?id=2' },
        { id: 4, title: 'Load Plan / SP Draft Creation (CFS only)', slug: '/cargo-services-details?id=3' },
        { id: 5, title: 'Load Plan / SP Draft Creation (CY only)', slug: '/cargo-services-details?id=4' },
        { id: 6, title: 'Creation of Manifest (CFS and CY)', slug: '/cargo-services-details?id=5' },
        { id: 7, title: 'VGM Submission', slug: '/cargo-services-details?id=6' },
        { id: 8, title: 'MBL Submission', slug: '/cargo-services-details?id=7' },
        { id: 9, title: 'ACI', slug: '/cargo-services-details?id=8' },
        { id: 10, title: 'AMS', slug: '/cargo-services-details?id=9' },
        { id: 11, title: 'ISF Draft Creation', slug: '/cargo-services-details?id=10' },
        { id: 12, title: 'Confirm Load Plan / Shipment Plan Final', slug: '/cargo-services-details?id=11' },
        { id: 13, title: 'Confirm Vessel Departure / Dispatch', slug: '/cargo-services-details?id=12' },
        { id: 14, title: 'Generate Memo Bill / Switch Bill', slug: '/cargo-services-details?id=13' },
        { id: 15, title: 'FCR/HBL Draft Creation', slug: '/cargo-services-details?id=14' },
        { id: 16, title: 'Print HBL', slug: '/cargo-services-details?id=15' },
        { id: 17, title: 'Local Charge / Debit Note Creation and Sending', slug: '/cargo-services-details?id=16' },
        { id: 18, title: 'MBL Confirm', slug: '/cargo-services-details?id=17' },
        { id: 19, title: 'Job Cost Input', slug: '/cargo-services-details?id=18' },
        { id: 20, title: 'Combine BL and other Commercial Documents', slug: '/cargo-services-details?id=19' },
        { id: 21, title: 'Send Pre Alert / Upload Doc Set', slug: '/cargo-services-details?id=20' },
        { id: 22, title: 'Agent Profit Share', slug: '/cargo-services-details?id=21' },
        { id: 23, title: 'Telex Release Confirmation', slug: '/cargo-services-details?id=22' },
        { id: 24, title: 'LIMA FCR Tool', slug: '/cargo-services-details?id=23' },


    ];

    const renderServices = () => {
        return servicesData.map((service) => (
            <li key={service.id} className={service.slug === '/cargo-services-details?id=0' ? 'active' : ''}>
                <Link onClick={() => setActive(service?.id)} to={service.slug}>{service.title}</Link>
            </li>
        ));
    };

    return (

        <>
            <HeaderOne></HeaderOne>
            <BreadCrumb title={dynmicData.process}></BreadCrumb>
            <div className="service__details p_relative ">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="service__details__left">
                                <div className="sidebar__widget">
                                    <div className="all__service">
                                        <h4 className="">All Services</h4>
                                    </div>
                                    <ul className="service__details__1__cat">
                                        {renderServices()}
                                    </ul>
                                </div>
                                <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>
                                    <h3>Contact Us For Any Require</h3>
                                    <div className="services__icon">
                                        <i className="icon-19"></i>
                                    </div>
                                    <p>Need help?  Call us:</p>
                                    <h4>(+91) 782 856 7519</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="service__right">
                                <figure>
                                    <img src={require("../../../public/images/resource/" + dynmicData?.image)} alt="" />
                                </figure>
                                {/* <h2>Enhancing Real-world Experiences with AR <span>Technology.</span> </h2> */}
                                <h5 className="mt-4">{dynmicData.description}</h5>
                                <div className="row">
                                    {dynmicData.keyFeatures.map((featurename) => (
                                        <div className="col-lg-6 col-md-12">
                                            <li>
                                                <Link to="#"><i className="icon-04"></i> {featurename}</Link>
                                            </li>
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="our_apporch_1">
                                            <h3>Approach</h3>
                                            <p>{dynmicData.approach}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="our_Commitment_1">
                                            <h3>Commitment</h3>
                                            <p>{dynmicData.commitment}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* {dynmicData.keyFeatures.map((featurename) => (
                                        <div className="col-lg-6 col-md-12">
                                            <li>
                                                <Link to="#"><i className="icon-04"></i> {featurename}</Link>
                                            </li>
                                        </div>
                                    ))} */}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FooterOne></FooterOne>
        </>
    );
};

export default Services;
