import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import shape5 from "../../assets/images/shapes/shape-10.png";


const Services = () => {
    pageTitle('Services');
    const servicesData = [
        {
            id: 1,
            title: 'Booking Entry',
            description: 'Initiate the cargo shipment process by entering booking details, including cargo type, quantity, and destination.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=0',
            data_aos_delay: '00ms',
        },
        {
            id: 2,
            title: 'Vessel Entry',
            description: 'Record vessel details, ensuring accurate scheduling and coordination with shipping timelines.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=1',
            data_aos_delay: '200ms',
        },
        {
            id: 3,
            title: 'Cargo Receipt Entry (CFS only)',
            description: 'Document cargo receipt entries specifically for Container Freight Stations (CFS) shipments, ensuring accurate inventory tracking.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=2',
            data_aos_delay: '400ms',
        },
        {
            id: 4,
            title: 'Load Plan / SP Draft Creation (CFS only)',
            description: 'Create load plans or shipping plan drafts for CFS shipments, optimizing cargo placement and distribution.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=3',
            data_aos_delay: '00ms',
        },
        {
            id: 5,
            title: 'Load Plan / SP Draft Creation (CY only)',
            description: 'Generate load plans or shipping plan drafts for Container Yard (CY) shipments, ensuring efficient cargo loading.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=4',
            data_aos_delay: '200ms',
        },
        {
            id: 6,
            title: 'Creation of Manifest (CFS and CY)',
            description: 'Compile and create cargo manifests for both Container Freight Stations and Container Yards, streamlining cargo documentation.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=5',
            data_aos_delay: '400ms',
        },
        {
            id: 7,
            title: 'VGM Submission',
            description: 'Submit Verified Gross Mass information to comply with international shipping regulations and ensure cargo safety.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=6',
            data_aos_delay: '400ms',
        },
        {
            id: 8,
            title: 'MBL Submission',
            description: 'Submit the Master Bill of Lading, a crucial document representing the carriers receipt of cargo.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=7',
            data_aos_delay: '400ms',
        },
        {
            id: 9,
            title: 'ACI',
            description: 'Advance Commercial Information submission to customs authorities for streamlined border clearance.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=8',
            data_aos_delay: '400ms',
        },
        {
            id: 10,
            title: 'AMS',
            description: 'Automated Manifest System submission to U.S. Customs and Border Protection for efficient cargo processing.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=9',
            data_aos_delay: '400ms',
        },
        {
            id: 11,
            title: 'ISF Draft Creation',
            description: 'Create drafts for Importer Security Filing (ISF) documents, ensuring compliance with U.S. Customs regulations.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=10',
            data_aos_delay: '400ms',
        },
        {
            id: 12,
            title: 'Confirm Load Plan / Shipment Plan Final',
            description: 'Confirm the final load plan or shipment plan, ensuring readiness for cargo transportation.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=11',
            data_aos_delay: '400ms',
        },
        {
            id: 13,
            title: 'Confirm Vessel Departure / Dispatch',
            description: 'Confirm the departure or dispatch of the vessel carrying the cargo, providing visibility into shipment progress.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=12',
            data_aos_delay: '400ms',
        },
        {
            id: 14,
            title: 'Generate Memo Bill / Switch Bill',
            description: 'Generate Memo Bills or Switch Bills for flexible cargo documentation and financial transactions.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=13',
            data_aos_delay: '400ms',
        }, 
        {
            id: 15,
            title: 'FCR/HBL Draft Creation',
            description: 'Create drafts for Forwarders Cargo Receipts (FCR) or House Bill of Lading (HBL), crucial documents for cargo ownership and liability.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=14',
            data_aos_delay: '400ms',
        }, 
        {
            id: 16,
            title: 'Print HBL',
            description: 'Print the finalized House Bill of Lading for official documentation and record-keeping.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=15',
            data_aos_delay: '400ms',
        }, 
        {
            id: 17,
            title: 'Local Charge / Debit Note Creation and Sending',
            description: 'Create local charges or debit notes and send them to relevant parties for transparent financial transactions.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=16',
            data_aos_delay: '400ms',
        }, 
        {
            id: 18,
            title: 'MBL Confirm',
            description: 'Confirm the Master Bill of Lading, validating the completeness and accuracy of cargo documentation.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=17',
            data_aos_delay: '400ms',
        }, 
        {
            id: 19,
            title: 'Job Cost Input',
            description: 'Input and track job costs associated with cargo services, ensuring financial transparency.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=18',
            data_aos_delay: '400ms',
        },
        {
            id: 20,
            title: 'Combine BL and other Commercial Documents',
            description: 'Consolidate Bill of Lading and other commercial documents for comprehensive cargo documentation.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=19',
            data_aos_delay: '400ms',
        },
        {
            id: 21,
            title: 'Send Pre Alert / Upload Doc Set',
            description: 'Send pre-alert notifications or upload complete document sets, providing timely information to stakeholders',
            icon: 'icon-12',
            href: '/cargo-services-details?id=20',
            data_aos_delay: '400ms',
        },
        {
            id: 22,
            title: 'Agent Profit Share',
            description: 'Calculate and allocate profit shares to agents involved in cargo handling and transportation.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=21',
            data_aos_delay: '400ms',
        },
        {
            id: 23,
            title: 'Telex Release Confirmation',
            description: 'Confirm Telex releases, facilitating the electronic release of cargo without the need for physical documents.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=22',
            data_aos_delay: '400ms',
        },
        {
            id: 24,
            title: 'LIMA FCR Tool',
            description: 'Utilize the LIMA FCR (Forwarders Cargo Receipt) tool for streamlined and standardized cargo receipt processes.',
            icon: 'icon-12',
            href: '/cargo-services-details?id=23',
            data_aos_delay: '400ms',
        },
        {
            id: 25,
            title: 'Vendor Release Milestone',
            description: 'Track and confirm vendor release milestones, ensuring timely and coordinated cargo movement.',
            icon: 'icon-12',
            href: '',
            data_aos_delay: '400ms',
        },
        {
            id: 26,
            title: 'AFR (for Japan Country)',
            description: 'Implement the Advanced Filing Rules (AFR) for cargo shipments to and from Japan, ensuring compliance with Japanese customs regulations.',
            icon: 'icon-12',
            href: '',
            data_aos_delay: '400ms',
        },

    ];
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });



    const handleSubmit = (e) => {
        e.preventDefault();

        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);

        // Clear the form fields after submission if needed
        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };
    const servicesDataTwo = [
        {
            id: 1,
            title: 'Content Marketing',
            number: '01',
            description: 'Providing legal advice, contract drafting, compliance assistance.',
            icon: 'icon-12',
            href: '/services-details',
            data_aos_delay: '00ms',
            buttontext: 'Read More',
        },
        {
            id: 2,
            title: 'Digital Marketing',
            number: '02',
            description: 'Providing legal advice, contract drafting, compliance assistance.',
            icon: 'icon-12',
            href: '/services-details',
            data_aos_delay: '200ms',
            buttontext: 'Read More',
        },
        {
            id: 3,
            title: 'SEO Marketing',
            number: '03',
            description: 'Providing legal advice, contract drafting, compliance assistance.',
            icon: 'icon-12',
            href: '/services-details',
            data_aos_delay: '400ms',
            buttontext: 'Read More',
        },
        {
            id: 4,
            title: 'Web Design',
            number: '04',
            description: 'Providing legal advice, contract drafting, compliance assistance.',
            icon: 'icon-12',
            href: '/services-details',
            data_aos_delay: '600ms',
            buttontext: 'Read More',
        },
    ];
    return (

        <>
            <HeaderOne></HeaderOne>
            <BreadCrumb></BreadCrumb>
            <div className="service__page p_relative see__pad">
                <div className="auto-container">
                    <div className="row">
                        {servicesData.map((item, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={index}>
                                <div className="service__block">
                                    <div className="service__icon">
                                        <i className={item.icon}></i>
                                        <div className="service__icon__two"></div>
                                    </div>
                                    <div className="service__text">
                                        <h4><Link to={item.href}>{item.title}</Link></h4>
                                        <p>{item.description}</p>
                                    </div>
                                    <div className="service__button">
                                        <Link to={item.href}><i className="icon-05"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* <section className="service__two p_relative">
            <div className="row">
                {servicesDataTwo.map((item, id)=>(
                    <div className="col-xl-3 col-lg-6 col-md-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={id}>
                        <div className="service__block__two p_relative">
                            <div className="service__image" style={{ backgroundImage: `url(${shape5})` }}>
                                
                            </div>
                            <div className="service__icon">
                                <div className="service__number">
                                    <h1>{item.number}</h1>
                                </div>
                                <i className={item.icon}></i>
                            </div>
                            <div className="service__text">
                                <h4><Link to={item.href}>{item.title}</Link></h4>
                                <p>{item.description}</p>
                            </div>
                            <div className="service__button__two">
                                <Link to={item.href}>{item.buttontext}</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section> */}
            {/* <div className="service__contact see__pad p_relative">
            <div className="auto-container">
                <div className="service__contact__block">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="service__form__data">
                                <div className="form__title">
                                    <div className="title__data">
                                        <div className="sub__title">
                                            <h4>Contact Us</h4>
                                        </div>
                                        <div className="title">
                                            <h2>Send message</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <form onSubmit={handleSubmit} method="post" action="contact.php" className="default-form"> 
                                        <div className="row clearfix">
                                            <div className="col-xl-6 form-group">
                                                <input type="name" name="name" placeholder="Your Name *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="email" name="email" placeholder="Your Email *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="subject" placeholder="Your Subject " required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="phone" placeholder="Your Phone " required="" />
                                            </div>
                                            <div className="ccol-xl-6 col-lg-12 form-group">
                                                <textarea name="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-xl-12 form-group message-btn btn-box">
                                                <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send your Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p_relative">
                            <div className="service__contact__img">
                                <figure>
                                    <img src={two} alt=" /" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
            <FooterOne></FooterOne>
        </>
    );
};

export default Services;
