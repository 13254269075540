import React from 'react';
import choose1 from "../../../assets/images/resource/why_choosen_us.png";
import aboutImg from "../../../assets/images/resource/abt_page_why_choosen.png";
import choose2 from "../../../assets/images/resource/choose-02.png";
import choose3 from "../../../assets/images/resource/choose-02.png";
import choose4 from "../../../assets/images/resource/choose-02.png";

const Choose = ({isAbout}) => {
    return (
        <section className="choose__us p_relative">
            <div className="choose__us__data">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="choose__block">
                                <figure>
                                    <img src={isAbout ? aboutImg : choose1} alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="choose__block__right">
                                <div className="title__data">
                                    <div className="sub__title">
                                        <h4>Why choose us</h4>
                                    </div>
                                    <div className="title">
                                        <h2>We Provide Your Best Work </h2>
                                    </div>
                                </div>
                                <div className="team__data">
                                    <div className="team__data__left">
                                        <figure>
                                        <img src={choose2} alt="" />
                                    </figure>
                                        
                                    </div>
                                    <div className="team__data__right">
                                        <h3>Comprehensive Expertise:</h3>
                                        <p>We cover it all – from the start of your idea to the final product. Our team's proficiency in both front-end and back-end technologies ensures a complete and effective approach to bringing your vision to life.</p>
                                    </div>
                                </div>
                                <div className="team__data">
                                    <div className="team__data__left">
                                        <img src={choose3} alt="" />
                                    </div>
                                    <div className="team__data__right">
                                        <h3>Reliability You Can Trust:</h3>
                                        <p>We take quality seriously. Through thorough software testing, we make sure that what we deliver is not only functional but robust and dependable. Your satisfaction is our priority.</p>
                                    </div>
                                </div>
                                <div className="team__data three">
                                    <div className="team__data__left">
                                        <img src={choose4} alt="" />
                                    </div>
                                    <div className="team__data__right">
                                        <h3>User-Friendly Designs:</h3>
                                        <p>We care about your users. Our designs are not just good-looking; they're easy to use. We focus on creating interfaces that make your products a joy to interact with, leaving a positive impact on your audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Choose;