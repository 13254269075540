import React from 'react';
import { Link } from 'react-router-dom';
import AboutUs from "../../../assets/images/resource/abt_2.png";
import aboutImg from "../../../assets/images/resource/about_page_abt.png";


const About = ({isAbout}) => {
  return (
    <section className="about__section p_relative see__pad">
      <div className="pattern-layer">
        {/* <div
          className="pattern-2 p_absolute"
          data-parallax='{"x": -50}'
          style={{ backgroundImage: `url(${BannerBg})` }}
        ></div> */}
      </div>
      {/* <div className="boild__text">
        <h1>DataMatrix</h1>
      </div> */}
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="about__block p_relative">
              <figure
                className="wow zoomIn animated"
                data-wow-delay="100ms"
                data-wow-duration="1500ms"
              >
                <img src={isAbout ? aboutImg : AboutUs} alt="" />
              </figure>
              {/* <div
                className="funfact__content about"
                style={{ backgroundImage: `url(${shape1})` }}
              >
                <div className="count-outer count-box">
                  <h1 className="count-text">
                  <CountUp start={0} end={10} /><span>K+</span>
                  </h1>
                  
                </div>
                <p>World wide Client</p>
              </div> */}
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5 col-md-12">
            <div className="about__text__block">
              <div className="sub__title">
                <h4>About Company</h4>
              </div>
              <div className="title two">
                <h2>
                "Improving real-life encounters through the integration of technology." 
                </h2>
              </div>
              <div className="texts">
                <p>As a leading organization, we are steadfast in our commitment to elevating 
                  real-world experiences through the strategic integration of cutting-edge technologies. 
                  Our proficiency extends across domains such as Artificial Intelligence, Machine Learning, mobile technology, 
                  and meticulous software testing. By adopting a rigorous approach to technological integration, we guarantee that
                  interactions are not only innovative but also embody the hallmarks of reliability, security, and professional excellence.</p>
              </div>
              <div className="btn-box">
                {!isAbout && (
                <Link to="/about" className="theme-btn theme-btn-one">
                  <i className="icon-02"></i> About more
                </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
